<template>
  <v-row>
    <v-col
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      class="py-1"
      :class="{ 'pr-1': $vuetify.breakpoint.mdAndDown }"
    >
      <v-card color="teal" dark height="60" class="d-flex align-center">
        <div class="pa-3">
          <p class="ma-0 caption">Usuarios</p>
          <p class="headline ma-0">
            <v-progress-circular
              indeterminate
              size="24"
              v-if="loader"
            ></v-progress-circular
            ><span v-else>{{ totalUsers }}</span>
          </p>
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      class="py-1"
      :class="{ 'pl-1': $vuetify.breakpoint.mdAndDown }"
    >
      <v-card color="#385F73" dark height="60" class="d-flex align-center">
        <div class="pa-3">
          <p class="ma-0 caption">Productos</p>
          <p class="headline ma-0">
            <v-progress-circular
              indeterminate
              size="24"
              v-if="loader"
            ></v-progress-circular
            ><span v-else>{{ totalProducts }}</span>
          </p>
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      class="py-1"
      :class="{ 'pr-1': $vuetify.breakpoint.mdAndDown }"
    >
      <v-card
        color="orange darken-3"
        dark
        height="60"
        class="d-flex align-center"
      >
        <div class="pa-3">
          <p class="ma-0 caption">Costo del inventario</p>
          <p class="headline ma-0">
            <v-progress-circular
              indeterminate
              size="24"
              v-if="loader"
            ></v-progress-circular
            ><span v-else
              ><span class="mr-1 text-subtitle-1">$</span
              >{{ totalCostInventory }}</span
            >
          </p>
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="6"
      sm="6"
      md="6"
      lg="4"
      xl="4"
      class="pb-0 pt-1"
      :class="{ 'pl-1': $vuetify.breakpoint.mdAndDown }"
    >
      <v-card color="blue-grey" dark height="60" class="d-flex align-center">
        <div class="pa-3">
          <p class="ma-0 caption">Inventariado</p>
          <p class="headline ma-0">
            <v-progress-circular
              indeterminate
              size="24"
              v-if="loader"
            ></v-progress-circular
            ><span v-else>{{ totalProductsInventoried }}</span>
          </p>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    totalProducts: {
      type: Number,
      default: () => 0
    },
    totalUsers: {
      type: Number,
      default: () => 0
    },
    totalCostInventory: {
      type: Number,
      default: () => 0
    },
    totalProductsInventoried: {
      type: Number,
      default: () => 0
    },
    loader: {
      default: false
    }
  }
};
</script>

<style></style>
