<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Analytics
        </h3>
        <p class="mb-1 ml-2">
          Reportes y estadisticas de tu negocio
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" class="py-1">
        <AppGeneralStatistics
          :totalProducts="totalProducts"
          :totalUsers="totalUsers"
          :totalCostInventory="totalCostInventory"
          :totalProductsInventoried="totalProductsInventoried"
          :loader="loaderGeneral"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" class="py-1">
        <AppTimeByOrders />
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="8" xl="9" class="py-1">
        <AppSales
          ref="salesContent"
          :changeBranchOfficeData="changeBranchOfficeData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sales from "../components/analytics/Sales";
import TimeByOrders from "../components/analytics/TimeByOrders";
import General from "../components/analytics/General";
import { database, firestore } from "@/config/firebase";
import { mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      totalProducts: 0,
      totalUsers: 0,
      totalCostInventory: 0,
      totalProductsInventoried: 0,
      loaderGeneral: 0
    };
  },
  components: {
    AppSales: Sales,
    AppTimeByOrders: TimeByOrders,
    AppGeneralStatistics: General
  },
  mounted() {
    this.getGeneralStats();
    this.getAverageSales();
    this.getMonthSales(this.currentBranchID);
  },
  methods: {
    async getGeneralStats() {
      this.loaderGeneral = true;
      const businessGeneralData = await database(
        "analytics/store/inventory"
      ).once("value");
      if (!businessGeneralData.exists()) {
        this.loaderGeneral = false;
        return;
      }
      const {
        total_money_cost,
        total_products,
        total_register_products
      } = businessGeneralData.val();
      this.totalProducts = total_products;
      this.totalCostInventory = total_money_cost;
      this.totalProductsInventoried = total_register_products;
      const businessState = await firestore("business")
        .doc(this.getBusiness.id)
        .collection("metadata")
        .doc("state")
        .get();
      const businessStateData = businessState.data();
      this.totalUsers = businessStateData.users.total_users;
      this.loaderGeneral = false;
    },
    async getAverageSales() {
      let salesAv = [];
      const salesAverageData = await database(
        "analytics/sales/average_by_day"
      ).once("value");
      if (salesAverageData.exists()) {
        salesAv = salesAverageData.val();
      }
      const data = [];
      for (let index = 0; index < 7; index++) {
        if (salesAv[index]) {
          data.push(salesAv[index]);
        } else {
          data.push(0);
        }
      }
      this.$apexcharts.exec("vuechart-days-average", "updateSeries", [
        { name: ["ventas"], data }
      ]);
    },
    async getMonthSales(branch_office) {
      this.$apexcharts.exec("vuechart-sales-monthly", "updateSeries", [
        { name: ["ventas"], data: [] }
      ]);
      const currentMonth = parseInt(moment().month()) + 1;
      const currentSale = `${currentMonth}${moment().year()}`;

      const salesAverageData = await database(
        `branch_offices/${branch_office}/analytics/sales/${parseInt(
          currentSale
        )}/sales_list`
      )
        .orderByChild("created")
        .limitToLast(400)
        .once("value");
      if (salesAverageData.exists()) {
        const salesSnap = salesAverageData.val();
        const sales = Object.keys(salesSnap).map(key => {
          return {
            x: moment.unix(salesSnap[key].created).format("L"),
            y: salesSnap[key].total_sold
          };
        });
        this.$apexcharts.exec("vuechart-sales-monthly", "updateSeries", [
          { name: ["ventas"], data: sales }
        ]);
      }
    },
    changeBranchOfficeData() {
      this.getMonthSales(this.currentBranchSelected);
    }
  },
  computed: {
    ...mapGetters("business", ["getBusiness"]),
    ...mapState("business", ["currentBranchID"]),
    ...mapState("app", ["currentBranchSelected"])
  }
};
</script>

<style></style>
