<template>
  <v-card color="card_background">
    <v-container>
      <p class="text-subtitle-1 mb-2">
        Ventas mensuales
      </p>
      <v-select
        v-model="currentBranchSelectedAnalytics"
        item-text="branch_office_name"
        @change="changeBranchOffice"
        item-value="id"
        dense
        :items="branchOffice"
        label="Sucursal"
        outlined
        hide-details
      ></v-select>
      <div>
        <AppSalesChart
          type="area"
          ref="sales"
          :options="options"
          :series="dataChart"
        ></AppSalesChart>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import ApexChart from "vue-apexcharts";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      currentBranchSelectedAnalytics: "",
      // :: Chart data
      options: {
        dataLabels: {
          enabled: false
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 1,
            opacityTo: 0.9
          }
        },
        colors: ["#2FEAAD"],
        chart: {
          id: "vuechart-sales-monthly",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: "#888888",
              fontFamily: "Poppins, Arial, sans-serif"
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: "#888888",
              fontFamily: "Poppins, Arial, sans-serif"
            }
          }
        },
        tooltip: {
          enabled: true,
          style: {
            fontFamily: "Poppins, Arial, sans-serif"
          }
        }
      },
      dataChart: [{ name: ["ventas"], data: [] }]
    };
  },
  computed: {
    ...mapState("business", ["branchOffice", "currentBranchID"]),
    ...mapState("app", ["currentBranchSelected"])
  },
  props: {
    changeBranchOfficeData: {
      type: Function
    }
  },
  methods: {
    ...mapActions("business", ["getBranchOfficeData"]),
    ...mapActions("app", ["changeSelectedBranchOffice"]),
    changeBranchOffice(branchID) {
      this.changeSelectedBranchOffice(branchID);
      this.changeBranchOfficeData();
    }
  },
  async beforeMount() {
    await this.getBranchOfficeData();
    this.changeSelectedBranchOffice(this.currentBranchID);
    this.currentBranchSelectedAnalytics = this.currentBranchID;
  },
  components: {
    AppSalesChart: ApexChart
  }
};
</script>
