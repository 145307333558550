<template>
  <v-card color="card_background">
    <v-container class="pb-0">
      <p class="text-subtitle-1 mb-0">
        Ventas promedio por día
      </p>
      <ApexChart
        height="230"
        type="bar"
        ref="apexBar2"
        :options="options"
        :series="series"
      ></ApexChart>
    </v-container>
  </v-card>
</template>

<script>
import ApexChart from "vue-apexcharts";
import moment from "moment";

export default {
  data() {
    return {
      // :: Chart data
      options: {
        dataLabels: {
          enabled: false
        },
        colors: [
          function({ value, dataPointIndex }) {
            let today = moment().format("d");
            if (today == dataPointIndex && value) {
              return "#f50057";
            } else {
              return "#7BAAF7";
            }
          }
        ],
        chart: {
          id: "vuechart-days-average",
          toolbar: {
            show: false
          }
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: "#888888",
              fontFamily: "Poppins, Arial, sans-serif"
            }
          },
          categories: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
            "Sábado"
          ]
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              colors: "#888888",
              fontFamily: "Poppins, Arial, sans-serif"
            }
          }
        }
      },
      series: [
        {
          name: "Venta promedio",
          data: []
        }
      ],
      currentBranchSelected: null
    };
  },
  components: {
    ApexChart
  }
};
</script>
